.card-container {
  justify-content: space-between;
  gap: 10px;
}

.home-card {
  width: 20rem;
  cursor: pointer;
  transition: transform 0.4s;
}

.home-card:hover {
  transform: scale(1.1);
  z-index: 1000;
}

.home-see-more-button {
  position: relative;
  float: right;
  top: 50px;
}

.home-see-more-button a {
  border-radius: 30px;
  padding: 10px 30px;
}

@media (max-width: 768px) {
  .card-container {
    gap: 20px;
  }

  .home-card {
    width: 100%;
    margin: auto 20px;
  }
}
