/** CARROUSEL */

.custom-banner {
  position: relative;
}

.custom-banner-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 45px;
}

.custom-banner-description {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: #fff;
  visibility: hidden;
  opacity: 0;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  margin-left: 10px;
  border-radius: 34px;
  transition: opacity 0.2s, visibility 0.2s;
}

.custom-banner:hover .custom-banner-description {
  visibility: visible;
  opacity: 1;
}

.custom-banner-image-description {
  transition: 0.2s;
  transform: translateY(1em);
}

.custom-banner-image-description img {
  border-bottom-left-radius: 40px 40px;
}

.custom-banner:hover .custom-banner-image-description {
  transform: translateY(0);
}

/** ARROW */

.custom-arrow {
  width: 100%;
  height: 0;
  position: absolute;
  top: 22vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 9.6vw;
  margin-left: 0.35vw;
}

.custom-arrow-image {
  cursor: pointer;
  width: auto;
  height: 50px;
}
