.embed-container {
  width: 99%;
  height: 100%;
  border: none;
}

.holder-pex {
  height: 800px;
  width: 100%;
  position: relative;
}

.bar-pex {
  background-color: rgb(244, 244, 244);
  position: absolute;
  top: -1px;
  left: 0;
  width: 100%;
  height: 45px;
}
